import '../styles/global.scss';
import '../styles/pages/download.scss';
import 'react-toastify/dist/ReactToastify.css';

import * as React from 'react';
import {HeadFC} from "gatsby";
import {
    logoDownload,
    starBaseline,
    starBaselineEmpty,
    graphDownload
} from "../images";
import {useState} from "react";
// import {fbq} from "@hutsoninc/gatsby-plugin-facebook-pixel";
import {navigate, Link} from 'gatsby';

const DownloadPage = () => {

    return (
        <div className="downloadPage">
            <style>
                {`
                    body{
                        background: #F4F4F4;
                    }
                `}
            </style>
            <div className="downloadPageBox">
                <div className="downloadPageLogo">
                    <a href="">
                        <img src={logoDownload} alt="" />
                    </a>
                </div>
                <h2 className="downloadPageTitle">Transform Mindless Scrolling<br /> into Productive Micro-Learning</h2>
                <img src={graphDownload} alt="" className="downloadPageGraph" />
                <p className="downloadPageText">A Clever Person should try this:</p>
                <ol className="downloadPageList">
                    <li>Stop scrolling.</li>
                    <li>Download <span>FeelGrow</span> on AppStore.</li>
                    <li>Use it for 15 minutes every day.</li>
                    <li>Transform yourself into the captivating centerpiece of any gathering.</li>
                </ol>
                <div className="downloadPageRating">
                    <div className="downloadPageStars">
                        <img src={starBaseline} alt="" />
                        <img src={starBaseline} alt="" />
                        <img src={starBaseline} alt="" />
                        <img src={starBaseline} alt="" />
                        <img src={starBaselineEmpty} alt="" />
                    </div>
                    <span>4.7 | Users Trust Us</span>
                </div>
                <div className="downloadPageBtn">
                    <Link to="https://feeeeeeeel.com/click?o=11&a=3&aff_click_id=lend">Download the app</Link>
                </div>
            </div>
        </div>
    )
}

export default DownloadPage;

export const Head: HeadFC = () => (
    <>
        <title>Download - AEER PLATFORM</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
    </>
);

